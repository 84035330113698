import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { FormControl, Grid, makeStyles, TextField, MenuItem, Divider, Button } from '@material-ui/core';
import * as yup from 'yup';

import checkRole from 'src/helpers/aclHelper';

// import { PhoneCountryDropdown } from 'src/components/phoneInput';
import Alert from 'src/components/alert';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import Tab from 'src/components/tab';
import { RepLabels } from './components/RepLabels';
import { CountryDropdown } from './components/CountryDropdown';
import { Reps } from './components/list/Reps';

import { useRepsData, useFetchReps, useRepsActions, useFetchRepLabels, useFetchCountries } from './state/hooks';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '100px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      '& > *': {
        width: 250,
      },
    },
  },
  buttonSecondary: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    height: 40,
    padding: theme.spacing(1, 2)
  },
  areaCode: {
    maxWidth: 105,
    '@media screen and (max-width: 959px)': {
      maxWidth: '100%',
    },
  },
}));

export default function Rep() {
  const classes = useStyles();

  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [countries, setCountries] = useState([]);
  const [errorMessage, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const { createRep } = useRepsActions();
  const { data, isLoading } = useRepsData();

  const isShowInfo = !(data.length > 0) && !isLoading;

  useFetchReps();
  useFetchRepLabels();
  useFetchCountries();

  const formik = useFormik({
    initialValues: {
      repName: '',
      phoneType: 0,
      country: '',
      areaCode: '',
      phone: '',
      ext: '',
      repID: '',
      label: '',
    },
    validationSchema: yup.object().shape({
      repName: yup.string().required(),
      phoneType: yup.number().required(),
      country: yup.string().required(),
      areaCode: yup.string()
        .when(['country'], (country, schema) => {
          if (countries.length < 1) {
            return schema;
          }

          const countryFinded = countries.find(countryItem => countryItem.country_code === country);

          if (!countryFinded?.reg_pattern) {
            return schema;
          }

          const areaCodeExceptionPattern = new RegExp('\\(\\?\\!(?<codeException>[0-9|]*)\\)');
          const matchExceptionData = countryFinded.reg_pattern.match(new RegExp(areaCodeExceptionPattern));

          const areaCodePattern = new RegExp('\\(\\?\\<area_code\\>(?<code>[0-9\\-\\[\\]\\{\\}\\,]*)\\)');
          const matchData = countryFinded.reg_pattern.match(new RegExp(areaCodePattern));

          if (matchData && matchData[1]) {
            return schema.matches(`^${(matchExceptionData && matchExceptionData[0]) ? matchExceptionData[0] : ''}${matchData[1]}$`, 'Incorrect area code.');
          }

          return schema;
        })
        .required(),
      phone: yup.string()
        .when(['country'], (country, schema) => {
          if (countries.length < 1) {
            return schema;
          }

          const countryFinded = countries.find(countryItem => countryItem.country_code === country);

          if (!countryFinded?.reg_pattern) {
            return schema;
          }

          const phoneNumberPattern = new RegExp('\\(\\?\\<number\\>(?<phone>[0-9\\-\\[\\]\\{\\}\\,]*)\\)');
          const matchData = countryFinded.reg_pattern.match(new RegExp(phoneNumberPattern));

          if (matchData && matchData[1]) {
            return schema.matches(`^${matchData[1]}$`, 'Incorrect phone number.');
          }

          return schema;
        })
        .required(),
      label: yup.string(),
      ext: yup.string().when('phoneType', {
        is: 1,
        then: yup.string().required(),
      }),
      repID: yup.string().when('phoneType', {
        is: 3,
        then: yup.string().required(),
      }),
    }),
    onSubmit: (values, actions) => {
      setIsLoadingNew(true);

      createRep({ ...values }).then((response) => {
        if (response && response.error && response.error.message) {
          setError(response.error.message);
        } else {
          const country = formik.values.country;

          setError(null);

          actions.resetForm();
          actions.setFieldValue('country', country, true);
        }

        setIsLoadingNew(false);
      });

      actions.setSubmitting(false);
    },
  });

  const handleChangeCountry = (country) => {
    formik.setFieldValue('country', country, true);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 1 || countries.length > 0) {
      return;
    }

    setCountries(data);
  }

  const handleExpand = () => {
    setIsExpanded(expanded => !expanded);
  };

  useEffect(() => {
    setIsExpanded(isShowInfo);
  }, [isShowInfo]);

  return (
    <>
      {checkRole(['admin', 'manager']) && (
        <Tab title="Add New Rep"
          onClick={handleExpand}
          expanded={isExpanded}
          customExpandedIcon={
            <Button
              size="medium"
              color="primary"
              className={classes.buttonSecondary}
            >
              {!isExpanded && <><span style={{ fontSize: 20, marginRight: 8, marginBottom: 4 }}>+</span> Add</>}
              {isExpanded && <>Minimize</>}
            </Button>
          }
        >
          <LoadingContainer height="auto" loading={isLoadingNew}>
            <FormControl margin="normal" fullWidth>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Rep Name"
                    name="repName"
                    value={formik.values.repName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    fullWidth
                    error={formik.touched.repName && Boolean(formik.errors.repName)}
                  />
                </Grid>

                <Grid item xs={12} md={2} style={{minWidth: '170px'}}>
                  <TextField
                    label="Phone Type"
                    name="phoneType"
                    value={formik.values.phoneType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    select
                    fullWidth
                    error={formik.touched.phoneType && Boolean(formik.errors.phoneType)}
                  >
                    <MenuItem value={0}>Direct</MenuItem>
                    <MenuItem value={1}>Direct + Ext</MenuItem>
                    <MenuItem value={2}>Group Hunt</MenuItem>
                    <MenuItem value={3}>Group + Rep ID</MenuItem>
                    <MenuItem value={4}>AI Phone Rep</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={2} style={{minWidth: '170px'}}>
                  <CountryDropdown
                    label="Country"
                    name="country"
                    size="small"
                    fullWidth
                    color="secondary"
                    value={formik.values.country}
                    countriesList={handleGetCountriesList}
                    handleChangeCountry={handleChangeCountry}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                  />
                </Grid>

                <Grid item xs={12} className={classes.areaCode} style={{minWidth: '90px'}}>
                  <TextField
                    label="Area code"
                    name="areaCode"
                    value={formik.values.areaCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    fullWidth
                    error={formik.touched.areaCode && Boolean(formik.errors.areaCode)}
                  />
                </Grid>

                <Grid item xs={12} md={formik.values.phoneType === 1 || formik.values.phoneType === 3 ? 1 : 2} style={{minWidth: '120px'}}>
                  <TextField
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    fullWidth
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                </Grid>

                {formik.values.phoneType === 1 && (
                  <>
                    <Grid item xs={12} md={1}>
                      <TextField
                        label="Ext"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        name="ext"
                        value={formik.values.ext}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ext && Boolean(formik.errors.ext)}
                      />
                    </Grid>
                  </>
                )}

                {formik.values.phoneType === 3 && (
                  <>
                    <Grid item xs={12} md={1}>
                      <TextField
                        label="Rep ID"
                        name="repID"
                        value={formik.values.repID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        error={formik.touched.repID && Boolean(formik.errors.repID)}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={2}>
                  <RepLabels
                    label="Label"
                    name="label"
                    value={formik.values.label}
                    onChange={formik.setFieldValue}
                    onBlur={formik.handleBlur}
                    error={formik.touched.label && Boolean(formik.errors.label)}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </LoadingContainer>

          <Divider className={classes.divider} />

          <div className={classes.buttonWrapper}>
            {
              errorMessage &&
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            }

            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </div>
        </Tab>
      )}

      <Reps data={data} isLoading={isLoading} />
    </>
  );
}
