/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import hexToRgba from 'hex-to-rgba';
import { Grid, makeStyles, TextField, MenuItem, ButtonBase, Typography, IconButton, SvgIcon } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import checkRole from 'src/helpers/aclHelper';

import GroupIcon from 'src/assets/icons/group.svg';
import DeleteIcon from 'src/assets/icons/delete.svg';
import EditIconAlt from 'src/assets/icons/edit.svg';

import { phoneFormatterSeparated } from 'src/helpers/phoneFormatterHelper';

import Alert from 'src/components/alert';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import Paper from 'src/components/paper';
import { CountryDropdown } from '../CountryDropdown';
import { RepLabels } from '../RepLabels';

const useStyles = makeStyles(() => ({
  groupTag: {
    backgroundColor: '#eeeeee',
    color: '#202239',
    fontSize: 14,
    padding: '5px 10px',
    fontWeight: 400,
  },
  greyColor: {
    color: '#3C3D52',
  },
  label: {
    padding: '2px 6px',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  areaCode: {
    maxWidth: 105,
    '@media screen and (max-width: 959px)': {
      maxWidth: '100%',
    },
  },
  saveButtons: {
    justifyContent: 'end',
  }
}));

const phoneTypeName = ['Direct', 'Direct + Ext', 'Group Hunt', 'Group + Rep ID', 'AI Phone Rep'];

export const RepItem = ({ value, labels, onRemoveRep, onUpdateRep }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [errorMessage, setError] = useState(null);

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape({
      repName: yup.string().required(),
      phoneType: yup.number().required(),
      country: yup.string().required(),
      areaCode: yup.string()
        .when(['country'], (country, schema) => {
          if (countries.length < 1) {
            return schema;
          }

          const countryFinded = countries.find(countryItem => countryItem.country_code === country);

          if (!countryFinded?.reg_pattern) {
            return schema;
          }

          const areaCodeExceptionPattern = new RegExp('\\(\\?\\!(?<codeException>[0-9|]*)\\)');
          const matchExceptionData = countryFinded.reg_pattern.match(new RegExp(areaCodeExceptionPattern));

          const areaCodePattern = new RegExp('\\(\\?\\<area_code\\>(?<code>[0-9\\-\\[\\]\\{\\}\\,]*)\\)');
          const matchData = countryFinded.reg_pattern.match(new RegExp(areaCodePattern));

          if (matchData[1]) {
            return schema.matches(`^${matchExceptionData ? matchExceptionData[0] : ''}${matchData[1]}$`, 'Incorrect area code.');
          }

          return schema;
        })
        .required(),
      phone: yup.string()
        .when(['country'], (country, schema) => {
          if (countries.length < 1) {
            return schema;
          }

          const countryFinded = countries.find(countryItem => countryItem.country_code === country);

          if (!countryFinded?.reg_pattern) {
            return schema;
          }

          const phoneNumberPattern = new RegExp('\\(\\?\\<number\\>(?<phone>[0-9\\-\\[\\]\\{\\}\\,]*)\\)');
          const matchData = countryFinded.reg_pattern.match(new RegExp(phoneNumberPattern));

          if (matchData[1]) {
            return schema.matches(`^${matchData[1]}$`, 'Incorrect phone number.');
          }

          return schema;
        })
        .required(),
      label: yup.string(),
      ext: yup.string().when('phoneType', {
        is: 1,
        then: yup.string().required(),
      }),
      repID: yup.string().when('phoneType', {
        is: 3,
        then: yup.string().required(),
      }),
    }),
    onSubmit: (values, actions) => {
      setIsLoading(true);

      onUpdateRep({ ...values })
        .then((response)=>{
          if (response && response.error && response.error.message) {
            setError(response.error.message);
          } else {
            const country = formik.values.country;

            setError(null);
            setEditMode(false);

            actions.resetForm();
            actions.setFieldValue('country', country, true);
          }

          setIsLoading(false);
        });

      actions.setSubmitting(false);
    },
  });

  const handleChangeCountry = (country) => {
    formik.setFieldValue('country', country, true);
  };

  const handleChangeLabel = (data) => {
    formik.setFieldValue('label', data.id);
  };

  const handleEnableEdit = () => {
    setEditMode(true);
  };

  const handleDisableEdit = () => {
    setEditMode(false);
    formik.setValues(value, false);
  };

  const handleRemoveRep = () => {
    const repId = value.id;

    onRemoveRep(repId);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 1 || countries.length > 0) {
      return;
    }

    setCountries(data);
  }

  const repLabel = useMemo(() => labels.find(label => label.id === formik.values.label), [labels, formik.values.label]);

  useEffect(() => {
    formik.setValues({ ...value, repID: value.repID ?? "" });
  },[value]);

  return (
    <Paper>
      {
        editMode
          ? (<LoadingContainer height="auto" loading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  label="Rep name"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  fullWidth
                  onChange={formik.handleChange}
                  name="repName"
                  value={formik.values.repName}
                  onBlur={formik.handleBlur}
                  error={formik.touched.repName && Boolean(formik.errors.repName)}
                />
              </Grid>

              <Grid item xs={12} md={2} style={{minWidth: '170px'}}>
                <TextField
                  label="Phone type"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  select
                  fullWidth
                  onChange={formik.handleChange}
                  name="phoneType"
                  value={formik.values.phoneType}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneType && Boolean(formik.errors.phoneType)}
                >
                  <MenuItem value={0}>Direct</MenuItem>
                  <MenuItem value={1}>Direct + Ext</MenuItem>
                  <MenuItem value={2}>Group Hunt</MenuItem>
                  <MenuItem value={3}>Group + Rep ID</MenuItem>
                  <MenuItem value={4}>AI Phone Rep</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={2} style={{minWidth: '170px'}}>
                <CountryDropdown
                  label="Country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  countriesList={handleGetCountriesList}
                  handleChangeCountry={handleChangeCountry}
                  size="small"
                  fullWidth
                  color="secondary"
                  error={formik.touched.country && Boolean(formik.errors.country)}
                />
              </Grid>

              <Grid item xs={12} md={2} className={classes.areaCode} style={{minWidth: '90px'}}>
                <TextField
                  label="Area code"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  fullWidth
                  name="areaCode"
                  value={formik.values.areaCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.areaCode && Boolean(formik.errors.areaCode)}
                />
              </Grid>

              <Grid item xs={12} md={formik.values.phoneType === 1 || formik.values.phoneType === 3 ? 1 : 2} style={{minWidth: '120px'}}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  fullWidth
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
              </Grid>

              {
                formik.values.phoneType === 1 && (
                  <>
                    <Grid item xs={12} md={1}>
                      <TextField
                        label="Ext"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        name="ext"
                        value={formik.values.ext}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ext && Boolean(formik.errors.ext)}
                      />
                    </Grid>
                  </>
                )
              }

              {
                formik.values.phoneType === 3 && (
                  <>
                    <Grid item xs={12} md={1}>
                      <TextField
                        label="Rep ID"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        fullWidth
                        name="repID"
                        value={formik.values.repID}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.repID && Boolean(formik.errors.repID)}
                      />
                    </Grid>
                  </>
                )
              }

              <Grid item xs={12} md={2}>
                <RepLabels
                  label="label"
                  name="label"
                  value={formik.values.label}
                  onChange={formik.setFieldValue}
                  onBlur={formik.handleBlur}
                  error={formik.touched.label && Boolean(formik.errors.label)}
                />
              </Grid>
            </Grid>

            <Grid alignItems="center" container spacing={2} className={classes.saveButtons}>
              {
                errorMessage &&
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              }

              <Grid item>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  <CheckIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton size="small" color="primary" onClick={handleDisableEdit}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </LoadingContainer>)
          : (<>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={3}>
                <span style={{ fontWeight: 700, marginRight: 12 }}>{`${formik.values.repName}`}</span> {phoneFormatterSeparated(formik.values.country, formik.values.areaCode, formik.values.phone)}
              </Grid>

              <Grid item md={2}>
                <Typography variant="body2">
                  {phoneTypeName[formik.values.phoneType]}
                </Typography>
              </Grid>

              <Grid item md={2}>
                {
                  repLabel && repLabel?.id !== '' && formik.values.phoneType === 0 && (
                    <span
                      className={classes.label}
                      style={{
                        backgroundColor: repLabel.color ? hexToRgba(repLabel.color, 0.2) : '#eeeeee',
                        color: repLabel.color ? repLabel.color : '#202239',
                      }}
                    >
                      {repLabel.label}
                    </span>
                  )
                }
              </Grid>

              <Grid item>
                <SvgIcon component={GroupIcon} viewBox="0 0 21 17" />
              </Grid>

              <Grid item xs>
                <Grid container spacing={1}>
                  {
                    value.groups.map(group => (
                      <Grid key={group.id} item>
                        <span className={classes.groupTag}
                          style={{
                            backgroundColor: group.color ? hexToRgba(group.color, 0.2) : '#eeeeee',
                            color: group.color ? group.color : '#202239',
                            maxWidth: '127px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                          }}
                        >{group.name}</span>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>

              {checkRole(['admin', 'manager']) && (<>
                <Grid item>
                  <ButtonBase onClick={handleEnableEdit}>
                    <EditIconAlt />
                  </ButtonBase>
                </Grid>

                <Grid item>
                  <ButtonBase onClick={handleRemoveRep}>
                    <DeleteIcon />
                  </ButtonBase>
                </Grid>
              </>)}
            </Grid>
          </>)
      }
    </Paper>
  );
};
