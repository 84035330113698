/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import hexToRgba from 'hex-to-rgba';
import clsx from 'clsx';

import { phoneFormatterSeparated } from 'src/helpers/phoneFormatterHelper';
import { useRepLabelsData } from 'src/features/routingRules/rep/state/hooks';
import Switch from 'src/components/switch';
import SwitchTag from 'src/components/switchTag';
import DragAndDropIcon from 'src/assets/icons/drag-and-drop.svg';


const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(2),
    borderRadius: 4,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    position: 'relative',
  },
  label: {
    padding: '2px 6px',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  dragAndDrop: {
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    borderRight: '1px solid #e6e6e6',
    paddingTop: '12px',
  },
  disabled: {
    // opacity: 0.4,
  },
}));

const phoneTypeName = ['Direct', 'Direct + Ext', 'Group Hunt', 'Group + Rep ID', 'AI Phone Rep'];

export const GroupRepsItem = ({
  id,
  repName,
  phone,
  phoneType,
  label,
  country,
  areaCode,
  isEnabled,
  onDelete,
  onUpdate,
  view,
}) => {
  const classes = useStyles();

  const { data: labels } = useRepLabelsData();

  const repLabel = useMemo(() => labels.find(({ id }) => id === label), [labels, label]);

  const handleChangeRepEnabling = event => {
    const isRepEnabled = +event.target.checked;

    onUpdate({ enabled: isRepEnabled });
  };

  const formatedPhone = phoneFormatterSeparated(country, areaCode, phone);

  const handleDelete = () => {
    onDelete(id);
  };

  return view ? (
    <Grid container spacing={3} alignItems="center" className={clsx(!isEnabled && classes.disabled)}>
      <Grid item xs={2}>
        <SwitchTag checked={!isEnabled} />
      </Grid>

      <Grid item xs>
        <Typography variant="subtitle2">
          {repName}
        </Typography>

      </Grid>

      <Grid item xs>
        <Typography variant="body2">
          {formatedPhone}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body2">
          {phoneTypeName[phoneType]}
        </Typography>
      </Grid>

      <Grid item xs>
        {repLabel && phoneType === 0 && (
          <span
            className={classes.label}
            style={{ backgroundColor: hexToRgba(repLabel.color, 0.2), color: repLabel.color }}
          >
            {repLabel.label}
          </span>
        )}
      </Grid>
    </Grid>
  ) : (
    <div className={classes.row}>
      <div className={classes.dragAndDrop}>
        <DragAndDropIcon />
      </div>

      <Grid container style={{paddingLeft: '50px'}} spacing={3} alignItems="center">
        <Grid item>
          <Switch checked={isEnabled} onChange={handleChangeRepEnabling} />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle2">
            {repName}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2">
            {formatedPhone}
          </Typography>
        </Grid>

        <Grid item md={2}>
          <Typography variant="body2">{phoneTypeName[phoneType]}</Typography>

        </Grid>

        <Grid item md={2}>
          {repLabel && phoneType === 0 && (
            <span
              className={classes.label}
              style={{ backgroundColor: hexToRgba(repLabel.color, 0.2), color: repLabel.color }}
            >
              {repLabel.label}
            </span>
          )}
        </Grid>

        <Grid item xs />

        <Grid item>
          <IconButton color="primary" size="small" onClick={handleDelete}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
